<template>
  <div class="livePageWrap container">
      <header>
        <el-row class="hedaer-Bg" type="flex">
          <el-col :span="12">
            <div class="grid-content header-left-content">
              <div class="logo-wrap">
                <img src="../../assets/image/homeImg/logo.png" />
              </div>
              <div class="app-title" @click="$router.push('/home')">
                职业卫生在线培训
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content header-right-content">
              <div @click="pageJumpLogin" v-if="userName == ''">登录</div>
              <div v-else class="userName-wrap">
                <el-dropdown placement="bottom">
                  <span class="el-dropdown-link">
                    学员{{ userName }}
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="$router.push('/accountSafe')">账号安全</el-dropdown-item>
                    <el-dropdown-item @click.native="$router.push('/login')">退出登录</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </el-col>
        </el-row>
        <navTab></navTab>
      </header>
      <el-main class='main-content'>
        <div class='title'>
            账号安全
        </div>
        <div class='safeItm'>
            <div class='icon'><i class='el-icon-unlock'></i></div>
            <div class='contentText'>
                <div>登录密码</div>
                <div>用于登录系统和保护账号信息</div>
            </div>
            <div class='button'>
                 <el-button type="primary" plain @click="$router.push('/upDatePassword')">修改</el-button>
            </div>
        </div>
        <div class='safeItm'>
            <div class='icon'><i class='el-icon-mobile-phone'></i></div>
            <div class='contentText'>
                <div>安全手机：{{phone}}</div>
                <div>安全手机可以用于登职业卫生在线培训，找回密码</div>
            </div>
        </div>
      </el-main>
      <footerCom></footerCom>
  </div>
</template>

<script>
import navTab from '@/components/navTab.vue'
import footerCom from '@/components/footer.vue'
export default {
  name: '',
  components: {
    footerCom,
    navTab
  },
  computed: {
  },
  props: {},
  watch: {},
  data () {
    return {
      userName: '',
      phone: '',
    }
  },
  methods: {
    /** 重新渲染观看页 */
    pageJumpLogin () {
      this.$router.push('/home')
    },
    // 获取local中用户信息
    getUserInfo () {
      let userinfo = localStorage.getItem('studentInfo') ? JSON.parse(localStorage.getItem('studentInfo')) : ''
      console.log(userinfo)
      if (userinfo) {
        let {stuName, stuMobile} = userinfo
        this.userName = stuName
        let swtichStr = '' + stuMobile[3] + stuMobile[4] + stuMobile[5] + stuMobile[6]
        this.phone = stuMobile.replace(swtichStr, '****')
      }
    },
  },
  mounted () {
    this.getUserInfo()
  },
}
</script>

<style scoped lang="scss">
.livePageWrap{
    position: relative;
    min-height: 100vh;
    background-color: #FAFAFA;
    header {
      background: url("../../assets/image/homeImg/headerBg.png");
      background-size: 100% 100%;
    }

  .hedaer-Bg {
    /* background-color:#6a8dea; */
    background-size: 100% 100%;
    height: 42px;
    width: 1440px;
    margin: 0 auto;
    line-height: 42px;
    padding: 0 100px;

    .header-left-content {
      font-size: 20px;
      color: white;
      font-weight: 800;
      display: flex;
      justify-content: start;

      .app-title {
        cursor: pointer;
      }

      .logo-wrap {
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 33px;
        }
      }
    }

    .header-right-content {
      font-size: 18px;
      color: white;
      height: 42px;
      display: flex;
      justify-content: end;
      align-items: center;

      .userName-wrap {
        height: 30px;
        line-height: 30px;
        align-items: center;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }

      ::v-deep .el-dropdown {
        font-size: 14px;
        color: #4774df;
        background-color: #fff;
        height: 30px;
        line-height: 30px;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        padding: 0px 10px;
      }
    }
  }
    .main-content{
        width: 1240px;
        padding: 30px;
        padding-bottom: 188px;
        margin:  0 auto 0;
        position: relative;
        .title{
            font-size: 22px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            margin-bottom: 20px
        }
        .safeItm{
            height: 86px;
            background: #FFFFFF;
            border-radius: 10px 10px 10px 10px;
            opacity: 1;
            border: 1px solid #707070;
            display: flex;
            padding: 18px 30px;
            margin-bottom: 30px;
            justify-content: space-between;
            align-items: center;
            .icon{
                width: 50px;
                color: #3388FF;
            }
            .contentText{
                flex:1;
                font-size: 16px;
            }
            .button{
                width: 100px;
            }
        }
    }
    /* footer{
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 50px;
        background: url('../../assets/image/homeImg/headerBg.png') no-repeat;
        background-size:100% 100%;
    } */
}
</style>
